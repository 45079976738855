import React from 'react';
import { Alert as BootstrapAlert, Container, Col, Row } from 'react-bootstrap';

const Alert = ({ notifications, setNotifications }) => {
    const handleClose = (id) => {
        setNotifications((prevStack) => prevStack.filter((item) => item.id !== id));
    };
    return (
        <Container className="position-fixed bottom-0 end-0 p-1 col-md-3">
            {notifications.map((notification) => (
                <BootstrapAlert
                    key={notification.id}
                    show={true}
                    variant={notification.type}
                    onClose={() => handleClose(notification.id)}
                    dismissible
                >
                    {notification.message}
                </BootstrapAlert>
            ))}
        </Container>
    );
};

export default Alert;
