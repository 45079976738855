import React from 'react';
import {Container, Nav, Navbar, Image} from "react-bootstrap";
import {Link} from 'react-router-dom';

const Header = ({user, logout}) => {

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-3">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    TGLink Constructor
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {
                            user &&
                            <>
                                <Nav.Link as={Link} to={"/"}>
                                    TapLinks
                                </Nav.Link>
                                <Nav.Link as={Link} to={"/redirect-infos"}>
                                    Redirects
                                </Nav.Link>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        {
                            user &&
                            <>
                                <Navbar.Text>
                                    {`Пользователь: ${user?.name}`}
                                </Navbar.Text>
                                <Nav.Link onClick={logout}>
                                    Выход
                                </Nav.Link>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;