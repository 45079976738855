import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({loader}) => {
    return (
        <div className="loader-overlay">
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            {loader.message && <p className="loader-message">{loader.message}</p>}
        </div>
    );
};

export default Loader;
