import './App.css';
import {Container} from "react-bootstrap";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Login from "./pages/Login";
import {useEffect, useState} from "react";
import Alert from "./components/Alert";
import Loader from "./components/Loader";
import {check} from "./http/userAPI";
import Main from "./pages/Main";
import ControlProject from "./pages/ControlProject";
import ControlRedirectInfos from "./pages/ControlRedirectInfos";

function App() {
    const [user, setUser] = useState(null);

    const [loader, setLoader] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const handleNotification = (message, type = 'info') => {
        const newNotification = {
            id: Date.now(),
            message,
            type,
        };
        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);

        setTimeout(() => {
            setNotifications((prevNotifications) =>
                prevNotifications.filter((item) => item.id !== newNotification.id)
            );
        }, 5000);
    };

    const checkAuth = () => {
        setLoader({status: true, message: 'Авторизация'});
        check()
            .then(setUser)
            .catch(err => handleNotification(err?.response?.data?.message || err.message, 'danger'))
            .finally(() => setLoader(null))
    }

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    }

    useEffect(() => {
        if (localStorage.getItem('token') && user === null)
            checkAuth()
    }, [user]);

    return (
        <Container className="App" fluid>
            <Router>
                <Header user={user} logout={logout}/>
                <Container style={{paddingBottom: 15}}>
                    {
                        user === null ?
                            <Routes>
                                < Route path="/" element={
                                    <Login setUser={setUser} handleNotification={handleNotification}
                                           setLoader={setLoader}/>
                                }/>
                                <Route
                                    path={'*'}
                                    element={<Navigate to={'/'} replace/>}
                                />
                            </Routes> :
                            <Routes>
                                <Route
                                    path={'/'}
                                    element={
                                        <Main user={user} handleNotification={handleNotification}
                                              setLoader={setLoader}/>
                                    }
                                />
                                <Route
                                    path={'/project/:id'}
                                    element={
                                        <ControlProject
                                            handleNotification={handleNotification}
                                            setLoader={setLoader}
                                        />
                                    }
                                />
                                <Route
                                    path={'/redirect-infos'}
                                    element={
                                        <ControlRedirectInfos
                                            handleNotification={handleNotification}
                                            setLoader={setLoader}
                                        />
                                    }
                                />
                                <Route
                                    path={'*'}
                                    element={<Navigate to={'/'} replace/>}
                                />
                            </Routes>
                    }
                </Container>
                <Alert notifications={notifications} setNotifications={setNotifications}/>
                {
                    loader !== null && <Loader loader={loader}/>
                }
            </Router>
        </Container>
    )
        ;
}

export default App;
