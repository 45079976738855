import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {json, useNavigate, useParams} from "react-router-dom";
import {IMAGES_EXT} from "../utils/consts";
import {createProject, deleteProject, getOneProject, updateProject} from "../http/projectAPI";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // импорт стилей

const ControlProject = ({handleNotification, setLoader}) => {
	const params = useParams();
	const navigate = useNavigate();
	const [project, setProject] = useState({
		name: '',
		subdomain: '',
		description: '',
		customCode: '',
		image: '',
		telegramLink: ''
	});
	const [dataImage, setDataImage] = useState();
	const [description, setDescription] = useState('');

	// const [link, setLink] = useState({
	// 	title: '',
	// 	url: '',
	// 	bgColor: '',
	// 	textColor: ''
	// });

	useEffect(() => {
		if (params.id !== 'new') {
			setLoader({status: true, message: 'Получение проекта'});
			getOneProject(params.id)
				.then(data => {
					setProject({...data});
					// setDescription(data.description)
				})
				.catch(err => {
					handleNotification(`Ошибка получения проекта: ${err}`);
					navigate('/');
				})
				.finally(() => setLoader(null));
		}
	}, []);

	useEffect(() => {
		setDescription(project.description);
	}, [project]);

	const onChangeImage = (event) => {
		const file = event.target.files[0];

		if (file) {
			setProject({...project, image: file});
			const reader = new FileReader();

			reader.onloadend = () => {
				setDataImage(reader.result);
			};

			reader.readAsDataURL(file);
		}
	};

	// const addButtonHandler = () => {
	// 	if (!link.title)
	// 		return handleNotification('Введите имя кнопки', 'danger');
	// 	if (!link.url)
	// 		return handleNotification('Введите ссылку кнопки', 'danger');
	// 	if (!link.bgColor)
	// 		return handleNotification('Выберите цвет фона', 'danger');
	// 	if (!link.textColor)
	// 		return handleNotification('Выберите цвет текста', 'danger');
	//
	// 	project.links.push(link);
	// 	setProject({...project});
	// 	setLink({
	// 		title: '',
	// 		url: '',
	// 		bgColor: '',
	// 		textColor: ''
	// 	});
	// };

	const saveProject = () => {
		if (!project.name)
			return handleNotification('Введите имя проекта', 'danger');
		if (!project.subdomain)
			return handleNotification('Введите имя поддомена', 'danger');
		if (!project.image && params.id === 'new')
			return handleNotification('Выберите картинку', 'danger');
		if (!description)
			return handleNotification('Введите описание', 'danger');
		if (!project.telegramLink)
			return handleNotification('Введите ссылку на Telegram', 'danger');
		// if (!project.links.length)
		// 	return handleNotification('Создайте минимум одну кнопку', 'danger');

		const formData = new FormData();
		formData.append('name', project.name);
		formData.append('subdomain', project.subdomain);
		if (params.id === 'new' || (dataImage && project.id !== 'new'))
			formData.append('image', project.image);

		formData.append('description', description);
		formData.append('customCode', project.customCode);
		formData.append('telegramLink', project.telegramLink);
		// for (const link of project.links)
		// 	formData.append('links[]', JSON.stringify(link));

		setLoader({status: true, message: 'Сохранение проекта'});
		if (params.id === 'new')
			createProject(formData)
				.then(() => {
					handleNotification('Проект создан', 'success');
					navigate('/');
				})
				.catch((err) => handleNotification(`Ошибка создания документа: ${err}`, 'danger'))
				.finally(() => setLoader(null));
		else
			updateProject(project.id, formData)
				.then(() => {
					handleNotification('Проект сохранен', 'success');
					navigate('/');
				})
				.catch((err) => handleNotification(`Ошибка сохранения документа: ${err}`, 'danger'))
				.finally(() => setLoader(null));
	};

	const deleteProjectHandler = () => {
		setLoader({status: true, message: 'Удаление проекта'});
		deleteProject(project.id)
			.then(() => {
				handleNotification('Проект удален', 'success');
				navigate('/');
			})
			.catch(err => handleNotification(`Ошибка удаления проекта: ${err}`))
			.finally(() => setLoader(null));
	};

	return (
		<Container>
			<h3 className={'mb-4'}>{params.id === 'new' ? 'Создание проекта' : 'Обновление проекта'}</h3>
			<Form className={'mb-3'}>
				<Form.Group className={'mb-3'}>
					<Form.Label>Имя проекта</Form.Label>
					<Form.Control
						type={'text'}
						value={project.name}
						onChange={e => setProject({...project, name: e.target.value})}
					/>
				</Form.Group>
				<Form.Group className={'mb-3'}>
					<Form.Label>Введите имя поддомена</Form.Label>
					<Form.Control
						type={'text'}
						value={project.subdomain}
						onChange={e => setProject({...project, subdomain: e.target.value.toLowerCase()})}
					/>
				</Form.Group>
				<Form.Group className={'mb-3'}>
					<Form.Label>Введите ссылку на Telegram (https://t.me/example)</Form.Label>
					<Form.Control
						type={'text'}
						value={project.telegramLink}
						onChange={e => setProject({...project, telegramLink: e.target.value})}
					/>
				</Form.Group>
				<Form.Group className={'mb-3'}>
					<Form.Label>Введите описание</Form.Label>
					<ReactQuill value={description} onChange={setDescription}/>
				</Form.Group>

				<Form.Group className={'mb-3'}>
					<Form.Label>Введите внешний код</Form.Label>
					<Form.Control
						type={'text'}
						as={'textarea'}
						aria-rowspan={5}
						value={project.customCode}
						onChange={e => setProject({...project, customCode: e.target.value})}
					/>
				</Form.Group>

				<Form.Group className={'mb-2'}>
					<Form.Label>Изображение</Form.Label>
					<Form.Control type={'file'}
					              accept={IMAGES_EXT.join(', ')}
					              onChange={onChangeImage}
					/>
				</Form.Group>
				{(dataImage || project.image) && (
					<img className={'mb-3'} src={dataImage || process.env.REACT_APP_API_PUBLIC_URL + project.image}
					     alt="Image"
					     style={{maxWidth: '100%', maxHeight: '200px'}}/>
				)}

				{/*	<Form.Group>*/}
				{/*		<Form.Label>Кнопки:</Form.Label>*/}
				{/*		<div>*/}
				{/*			{*/}
				{/*				project.links.length === 0 ?*/}
				{/*					<span>Кнопки отсутствуют</span> :*/}
				{/*					<Table striped bordered hover>*/}
				{/*						<thead>*/}
				{/*						<tr>*/}
				{/*							<th>#</th>*/}
				{/*							<th>Название</th>*/}
				{/*							<th>Ссылка</th>*/}
				{/*							<th>Цвет фона</th>*/}
				{/*							<th>Цвет текста</th>*/}
				{/*							<th>Удаление</th>*/}
				{/*						</tr>*/}
				{/*						</thead>*/}
				{/*						<tbody>*/}
				{/*						{project.links.map((el, index) => (*/}
				{/*							<tr key={index}>*/}
				{/*								<td>{index + 1}</td>*/}
				{/*								<td>{el.title}</td>*/}
				{/*								<td className={'text-white'}>*/}
				{/*									<a href={el.url} target={"_blank"} rel="noopener noreferrer">{el.url}</a>*/}
				{/*								</td>*/}
				{/*								<td style={{backgroundColor: el.bgColor}}></td>*/}
				{/*								<td style={{backgroundColor: el.textColor}}></td>*/}
				{/*								<td*/}
				{/*									style={{cursor: 'pointer', fontSize: 20}}*/}
				{/*									className={'fw-bold d-flex justify-content-center'}*/}
				{/*									onClick={e => setProject({*/}
				{/*										...project,*/}
				{/*										links: project.links.filter((_, ind) => ind !== index)*/}
				{/*									})}*/}
				{/*								>×*/}
				{/*								</td>*/}
				{/*							</tr>*/}
				{/*						))}*/}
				{/*						</tbody>*/}
				{/*					</Table>*/}
				{/*			}*/}
				{/*		</div>*/}
				{/*	</Form.Group>*/}
				{/*</Form>*/}

				{/*<Form className={'mb-3'}>*/}
				{/*	<h5>Добавление кнопок</h5>*/}
				{/*	<Form.Group className={'mb-2'}>*/}
				{/*		<Form.Label>Название</Form.Label>*/}
				{/*		<Form.Control*/}
				{/*			value={link.title}*/}
				{/*			onChange={e => setLink({...link, title: e.target.value})}*/}
				{/*			required*/}
				{/*		/>*/}
				{/*	</Form.Group>*/}
				{/*	<Form.Group className={'mb-2'}>*/}
				{/*		<Form.Label>Ссылка</Form.Label>*/}
				{/*		<Form.Control*/}
				{/*			value={link.url}*/}
				{/*			onChange={e => setLink({...link, url: e.target.value})}*/}
				{/*			required*/}
				{/*		/>*/}
				{/*	</Form.Group>*/}
				{/*	<Form.Group className={'mb-2'}>*/}
				{/*		<Form.Label>Цвет фона</Form.Label>*/}
				{/*		<Form.Control*/}
				{/*			type={'color'}*/}
				{/*			value={link.bgColor}*/}
				{/*			onChange={e => setLink({...link, bgColor: e.target.value})}*/}
				{/*		/>*/}
				{/*	</Form.Group>*/}
				{/*	<Form.Group className={'mb-2'}>*/}
				{/*		<Form.Label>Цвет текста</Form.Label>*/}
				{/*		<Form.Control*/}
				{/*			type={'color'}*/}
				{/*			value={link.textColor}*/}
				{/*			onChange={e => setLink({...link, textColor: e.target.value})}*/}
				{/*		/>*/}
				{/*	</Form.Group>*/}
				{/*	<Button onClick={addButtonHandler}>Добавить кнопку</Button>*/}
			</Form>
			<div className={'d-flex justify-content-start'}>
				<Button className={'me-3'} onClick={saveProject}>Сохранить</Button>
				<Button variant={'danger'} onClick={deleteProjectHandler}>Удалить проект</Button>
			</div>
		</Container>
	);
};

export default ControlProject;
