import React, {useEffect, useState} from 'react';
import {Accordion, Button, Container, Form} from "react-bootstrap";
import {createRedirectInfo, deleteRedirectInfo, getAllRedirectInfos, updateRedirectInfo} from "../http/redirectInfoAPI";

const ControlRedirectInfos = ({handleNotification, setLoader}) => {
    const [redirectInfos, setRedirectInfos] = useState([]);
    const [newRedirectInfo, setNewRedirectInfo] = useState({
        name: '',
        path: '',
        redirect: ''
    });

    useEffect(() => {
        setLoader({status: true, message: 'Получение редиректов'});
        getAllRedirectInfos()
            .then(setRedirectInfos)
            .catch((err) => {
                handleNotification(`Ошибка получение редиректов: ${err?.response?.data?.message || err.message}`, 'danger');
            })
            .finally(() => setLoader(null));
    }, []);

    const createItemHandler = () => {
        setLoader({status: true, message: 'Создание редиректа'});
        createRedirectInfo(newRedirectInfo)
            .then(res => {
                handleNotification('Редирект создан', 'success');
                setRedirectInfos([...redirectInfos, res]);
                setNewRedirectInfo({name: '', path: '', redirect: ''});
            })
            .catch((err) => {
                handleNotification(`Ошибка создания редиректа: ${err?.response?.data?.message || err.message}`, 'danger');
            })
            .finally(() => setLoader(null));
    }

    const editFormItemHandler = (id, field, value) => {
        redirectInfos.find(el => el.id === id)[field] = field !== 'name' ? value.replace(/\s/g, "") : value;
        setRedirectInfos([...redirectInfos]);
    }

    const updateItemHandler = (id) => {
        setLoader({status: true, message: 'Обновление редиректа'});
        const item = redirectInfos.find(el => el.id === id);
        updateRedirectInfo(item)
            .then(() => {
                handleNotification('Редирект изменен', 'success');
            })
            .catch((err) => {
                handleNotification(`Ошибка обновления редиректа: ${err?.response?.data?.message || err.message}`, 'danger');
            })
            .finally(() => setLoader(null));
    }

    const deleteItemHandler = (id) => {
        setLoader({status: true, message: 'Удаление редиректа'});
        deleteRedirectInfo({id})
            .then(() => {
                setRedirectInfos([...redirectInfos.filter(el => el.id !== id)]);
                handleNotification('Редирект удален', 'success');
            })
            .catch((err) => {
                handleNotification(`Ошибка удаления редиректа: ${err?.response?.data?.message || err.message}`, 'danger');
            })
            .finally(() => setLoader(null));
    }

    return (
        <Container>
            <h3>Информация о редиректах</h3>
            {
                redirectInfos.length === 0 ? <h5>Редиректы отсутсвуют</h5> :
                    <Accordion className={'mt-5'}>
                        {redirectInfos.map(el =>
                            <Accordion.Item key={el.id} eventKey={el.id}>
                                <Accordion.Header>{el.name ? `${el.name}` : ''}<b>&nbsp;Path: </b>{el.path}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>
                                                <a
                                                    target={"_blank"}
                                                    href={`https://potrade.online/${el.path !== 'main' ? el.path : ''}`}>
                                                    {`https://potrade.online/${el.path !== 'main' ? el.path : ''}`}
                                                </a>
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group className={'mt-2'}>
                                            <Form.Label>Название редиректа</Form.Label>
                                            <Form.Control type={'text'} value={el.name}
                                                          onChange={
                                                              e => editFormItemHandler(
                                                                  el.id, 'name', e.target.value
                                                              )
                                                          }/>
                                        </Form.Group>
                                        <Form.Group className={'mt-2'}>
                                            <Form.Label>Источник редиректа example.com/<b>alextop</b></Form.Label>
                                            <Form.Control type={'text'} value={el.path} disabled={true}/>
                                        </Form.Group>
                                        <Form.Group className={'mt-2'}>
                                            <Form.Label>Ссылка на редирект</Form.Label>
                                            <Form.Control type={'text'} value={el.redirect}
                                                          onChange={
                                                              e => editFormItemHandler(
                                                                  el.id, 'redirect', e.target.value
                                                              )
                                                          }/>
                                        </Form.Group>
                                        <Button
                                            className={'mt-2'}
                                            disabled={!el.path || !el.redirect}
                                            onClick={() => updateItemHandler(el.id)}
                                        >Сохранить</Button>
                                        <Button
                                            style={{marginLeft: 10}}
                                            className={'mt-2'}
                                            variant={'danger'}
                                            onClick={() => deleteItemHandler(el.id)}
                                        >Удалить</Button>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
            }

            <Form className={'mt-5 mb-2'}>
                <h5>Форма создание нового редиректа</h5>
                <Form.Group>
                    <Form.Label>Название редиректа</Form.Label>
                    <Form.Control type={'text'} value={newRedirectInfo.name}
                                  onChange={
                                      e => setNewRedirectInfo({
                                          ...newRedirectInfo,
                                          name: e.target.value
                                      })
                                  }/>
                </Form.Group>
                <Form.Group className={'mt-2'}>
                    <Form.Label>Источник редиректа example.com/<b>alextop</b></Form.Label>
                    <Form.Control type={'text'} value={newRedirectInfo.path}
                                  onChange={
                                      e => setNewRedirectInfo({
                                          ...newRedirectInfo,
                                          path: e.target.value.toLowerCase().replace(/\s/g, "")
                                      })
                                  }/>
                </Form.Group>
                <Form.Group className={'mt-2'}>
                    <Form.Label>Ссылка на редирект</Form.Label>
                    <Form.Control type={'text'} value={newRedirectInfo.redirect}
                                  onChange={
                                      e => setNewRedirectInfo({
                                          ...newRedirectInfo,
                                          redirect: e.target.value.replace(/\s/g, "")
                                      })
                                  }/>
                </Form.Group>
                <Button
                    className={'mt-2'}
                    disabled={!newRedirectInfo.path || !newRedirectInfo.redirect}
                    onClick={createItemHandler}
                >Создать</Button>
            </Form>
        </Container>
    );
};

export default ControlRedirectInfos;