import {$authHost} from "./index";

export const getAllRedirectInfos = async () => {
    const {data} = await $authHost.get('api/redirect-info');
    return data;
}

export const createRedirectInfo = async ({name, path, redirect}) => {
    const {data} = await $authHost.post('api/redirect-info', {
        name,
        path,
        redirect
    });
    return data;
}

export const updateRedirectInfo = async ({id, name, redirect}) => {
    const {data} = await $authHost.patch(`api/redirect-info/${id}`, {
        name,
        redirect
    });
    return data;
}

export const deleteRedirectInfo = async ({id}) => {
    const {data} = await $authHost.delete(`api/redirect-info/${id}`);
    return data;
}