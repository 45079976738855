import React, {useState} from 'react';
import {Button, Container, Form} from "react-bootstrap";
import {$host} from "../http";
import {login} from "../http/userAPI";

const Login = ({setUser, handleNotification, setLoader}) => {
    const [inputLogin, setInputLogin] = useState('');
    const [inputPassword, setInputPassword] = useState('');

    const handleFormAuth = (e) => {
        e.preventDefault();
        setLoader({status: true, message: 'Авторизация'});

        login(inputLogin, inputPassword)
            .then(data=>{
                setUser(data);
            })
            .catch(err=>{
                localStorage.removeItem('token');
                handleNotification(err?.response?.data?.message || err.message, 'danger')
            })
            .finally(()=>setLoader(null));
    };

    return (
        <Container>
            <Form className="mt-4" onSubmit={handleFormAuth}>
                <Form.Group className="mb-3" controlId="formBasicLogin">
                    <Form.Label>Логин</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Введите логин"
                        value={inputLogin}
                        onChange={(e) => setInputLogin(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Введите пароль"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={!inputLogin && !inputPassword}>
                    Авторизация
                </Button>
            </Form>
        </Container>
    );
};

export default Login;