import {$authHost} from "./index";

export const getAllProjects = async () => {
    const {data} = await $authHost.get('api/project');
    return data;
}

export const getOneProject = async (name) => {
    const {data} = await $authHost.get(`api/project/${name}`);
    return data;
}

export const deleteProject = async (id) => {
    const {data} = await $authHost.delete(`api/project/${id}`);
    return data;
}

export const createProject = async (body) => {
    const {data} = await $authHost.post('api/project', body);
    return data;
}

export const updateProject = async (id, body) => {
    const {data} = await $authHost.patch(`api/project/${id}`, body);
    return data;
}